import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import * as JsSearch from 'js-search';
import List from '../components/list';
import Layout from '../components/layout';

export default (props) => {
    const {
        location,
    } = props;

    const { search: query } = location;
    const formattedQuery = decodeURIComponent(query).replace('?q=', '');

    return (
        <StaticQuery
            query={graphql`
                query {
                    allSnus {
                        edges {
                            node {
                                brandName
                                brand
                                name
                                type
                                format
                                weight
                                nicotine
                                strength
                                image
                                slug
                                productImage {
                                    childImageSharp {
                                        fluid(maxWidth: 250, maxHeight: 250) {
                                            aspectRatio
                                            src
                                            srcSet
                                            sizes
                                        }
                                    }
                                }
                                prices {
                                    price
                                    url
                                    reseller {
                                        resellerName
                                        shippingPrice
                                    }
                                }
                            }   
                        }
                    }
                }
            `}
        render={({ allSnus }) => {
            const data = allSnus.edges.map(s => ({ ...s.node }));
            const dataToSearch = new JsSearch.Search('brandName');
            dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
            dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
            dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex('brandName');
            const tokenizer = {
                tokenize(text) {
                    return text
                    .replace('é', 'e')
                    .replace('.', '')
                    .split(' ')
                    .filter(
                        (text) => text // Filter empty tokens
                    );
                }
            }
            dataToSearch.tokenizer = tokenizer;
            dataToSearch.addIndex('brandName');
            dataToSearch.addDocuments(data);
            const searchResults = dataToSearch.search(formattedQuery);
            const hasResults = searchResults.length > 0;
            const dataToShow = hasResults ? searchResults : data;

            return (
                <Layout>
                    {hasResults ? (
                        <h1 className="text-xl mt-2">
                            Du sökte på: <br />
                            <span className="text-4xl font-bold">
                                {formattedQuery}
                            </span>
                        </h1>
                        ) : (
                            <h1 className="text-3xl mt-2">
                                Hittade inga resultat, så här har du alla prillor:
                            </h1>
                        )}
                    <List data={dataToShow} />
                </Layout>
        )}}
    />
)};
